<template>
    <div class="container">
        <div class="periphery" style="min-height: 80vh; padding-top: 10vh">
            <el-form ref="form"
                     class="form"
                     label-position="left"
                     :model="form"
                     size="small"
                     :rules="ruleForm"
                     label-width="100px">
                <el-form-item label="机器码" prop="machineCode">
                    <el-input
                            v-model="form.machineCode"
                            clearable
                            placeholder="请输入机器码"></el-input>
                </el-form-item>
                <el-form-item label="有效期" prop="validityTime">
                    <el-select v-model="form.validityTime"
                               clearable
                               placeholder="请选择有效期"
                               style="width: 100%">
                        <el-option v-for="(item, index) in dates"
                                   :key="index"
                                   :label="item.label"
                                   :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="申请公司" prop="comName">
                    <el-input
                            v-model="form.comName"
                            clearable
                            placeholder="请输入申请公司"></el-input>
                </el-form-item>
                <el-form-item label="申请项目" prop="projectName">
                    <el-input
                            v-model="form.projectName"
                            clearable
                            placeholder="请输入申请项目"></el-input>
                </el-form-item>
                <el-form-item style="text-align: right">
                    <el-button
                            type="success"
                            icon="el-icon-check"
                            @click="submitForm('form')"
                    >确定
                    </el-button
                    >
                    <el-button
                            type="warning"
                            icon="el-icon-refresh"
                            @click="resetForm('form')"
                    >重置
                    </el-button
                    >
                </el-form-item>
            </el-form>

            <el-dialog title="提示"
                       :visible.sync="dialogVisible"
                       width="50%"
                       center
                       :close-on-click-modal="false"
                       :before-close="handleClose">
                <div style="text-align: center;" id="log">
                    <div>
                        <img src="@/assets/image/success.png" class="img" alt="">
                        <div class="successFont">授权成功</div>
                    </div>
                    <el-row>
                        <el-col :span="4" :push="2" class="key">公司</el-col>
                        <el-col :span="18" :push="1" class="value">{{comName}}</el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="4" :push="2" class="key">项目</el-col>
                        <el-col :span="18" :push="1" class="value">{{projectName}}</el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="4" :push="2" class="key">申请日期</el-col>
                        <el-col :span="18" :push="1" class="value">{{sendTime}}</el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="4" :push="2" class="key">有效期限</el-col>
                        <el-col :span="18" :push="1" class="value">{{validityTime}}</el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="4" :push="2" class="key">验证码</el-col>
                        <el-col :span="18" :push="1" class="value" style="min-width: 400px;">{{verificationCode}}</el-col>
                    </el-row>
                    <!--<div style="font-size: 14px; color: #FF4A3B; margin-top: 15px;">提示:将验证码发送给申请单位，此页面关闭后验证码不可见</div>-->
                    <el-row>
                        <el-col :span="24" style="font-size: 14px; color: #FF4A3B;">
                            提示:将验证码发送给申请单位，此页面关闭后验证码不可见
                        </el-col>
                    </el-row>
                </div>
                <span slot="footer" class="dialog-footer">
                  <el-button @click="claer" size="small">取 消</el-button>
                  <el-button type="primary" size="small" @click="claer">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import {getToken} from "@/util/token";

    export default {
        name: "index",
        data() {
            return {
                form: {
                    machineCode: "",
                    validityTime: null,
                    comName: '',
                    projectName: '',
                },
                ruleForm: {
                    machineCode: [
                        {required: true, message: '请输入机器码', trigger: 'blur'}
                    ],
                    validityTime: [
                        {required: true, message: '请输选择有效期', trigger: 'change'}
                    ],
                    comName: [
                        {required: true, message: '请输入申请公司', trigger: 'blur'}
                    ],
                    projectName: [
                        {required: true, message: '请输入申请项目', trigger: 'blur'}
                    ]
                },
                dates: [
                    {
                        value: 1,
                        label: '1月'
                    },
                    {
                        value: 3,
                        label: '3月'
                    },
                    {
                        value: 6,
                        label: '6月'
                    },
                    {
                        value: 12,
                        label: '12月'
                    },
                    {
                        value: 24,
                        label: '24月'
                    },
                    {
                        value: 36,
                        label: '36月'
                    },
                    {
                        value: '',
                        label: '不限'
                    }
                ],
                dialogVisible: false,
                transitionDateStr: null,
                comName: '', // 公司名称
                projectName: '', // 项目名称
                sendTime: '', // 申请日期
                validityTime: '', // 有效期限
                verificationCode: '', // 验证码
            };
        },
        mounted() {

        },
        methods: {
            /**
             * 提交
             * @param form
             */
            submitForm(form) {
                this.transitionDate();
                this.$refs[form].validate(valid => {
                    if (valid) {
                        let query = {
                                machineCode: this.form.machineCode,
                                validityTime: this.transitionDateStr,
                                comName: this.form.comName,
                                projectName: this.form.projectName
                            };
                        // console.log(query);
                        this.add(query);
                    } else {
                        console.log("error submit!!");
                        return false;
                    }
                });
            },
            /**
             * 重置
             * @param form
             */
            resetForm(form) {
                this.$refs[form].resetFields();
                this.form.machineCode = '';
                this.form.validityTime = null;
                this.form.comName = '';
                this.form.projectName = '';
            },
            handleClose (done) {
                this.$confirm('确认关闭？').then(() => {
                    done();
                    this.resetForm('form');
                }).catch(() => {})
            },
            /**
             * 日期格式转换
             */
            transitionDate () {
                const d = new Date();
                let y = d.getFullYear();
                let m = d.getMonth() + 1;
                let dd = d.getDate();
                let str = `${y}-${m < 10 ? '0'+m : m}-${dd < 10 ? '0'+dd : dd}`
                this.transitionDateStr = this.form.validityTime ? this.$moment(str).add(this.form.validityTime, "months").format('YYYY-MM-DD') : '';
                console.log(this.transitionDateStr)
            },
            /**
             * 授权中心-添加
             * @param data
             */
            add (data) {
                this.post(this.config.apiUrl.add, data).then(res => {
                    if (res.code == 200) {
                        let id = res.message;
                        this.$message({
                            message: '提交成功',
                            type: 'success',
                            duration: 1000,
                            onClose: () => {
                                this.dialogVisible = true;
                                this.queryById(id);
                            }
                        })
                    } else {
                        this.msgError(res.message);
                    }
                });
            },
            /**
             * 授权中心-通过id查询
             * @param id
             */
            queryById (id) {
                this.get(this.config.apiUrl.queryById, {
                    id: id
                }).then(res =>{
                    console.log(res)
                    if (res.code == 200) {
                        this.comName = res.result.comName;
                        this.projectName = res.result.projectName;
                        this.sendTime = res.result.sendTime;
                        this.validityTime = res.result.validityTime;
                        this.verificationCode = res.result.verificationCode;
                    } else {
                        this.msgError(res.message);
                    }
                })
            },
            /**
             * 清除表单填写内容
             */
            claer () {
                this.dialogVisible = false;
                this.resetForm('form');
            }
        }
    };
</script>

<style lang="scss" scoped>
    .container {
        /*background-color: #fff;*/
        /*min-height: 88vh;*/
        width: 100%;
    }

    .form {
        width: 40%;
        margin: 0 auto;
    }

    /deep/ .ql-editor {
        height: 300px;
    }

    #log {
        .el-row {
            height: 36px;
            line-height: 36px;
        }
    }

    .img {
        width: 88px;
        height: 88px;
    }

    .successFont {
        font-size: 16px;
        color: #10D79C;
        margin-top: 10px;
        margin-bottom: 30px;
    }

    .key {
        font-size: 14px;
        color: #90909E;
        text-align: left;
        min-width: 100px;
    }

    .value {
        font-size: 14px;
        color: #333333;
        text-align: left;
    }
</style>
